import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/remit.jpg';
import pic2 from '../assets/images/chart.jpg';
import pic3 from '../assets/images/escrow.jpg';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon fa-cubes"></span>
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Crypto/Fiat Payments and Remittances</h2>
            <p>
              Access the most reliable and convenient platform for individual payments and 
              peer to peer transactions through our service on BitLipa. Securely send and Receive money
              in your preferred currency from any location globally in cryptocurrency and fiat 
              currency.
            </p>
            <a href="/#" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Bitcoin OTC Desk</h2>
            <p>
              Enjoy the best crypto liquidity in Africa through our robust network 
              of fiat and crypto liquidity partners. Point50 Capital provides same 
              day settlement in an easy to use interface at a flat rate fee per transaction.
              We don't charge crypto withdrawal fees.
            </p>
            <a href="/#" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Bitcoin Escrow Facility</h2>
            <p>
              Onboarded traders can rely on Point50 Capital as a neutral 
              party to settle trades and exchanges. Our escrow service is 
              takes a privacy first approach to maintain anonymity of parties involved. 
              Point50 takes utmost care to closely work with our customers with 
              round the clock support from our trading specialists.
            </p>
            <a href="/#" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="four">
        
      </section>
    </section>
  </Layout>
);

export default IndexPage;
